/* 
general 
-------
*/
body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.ant-layout {
  background: #fff !important;
}

.mainLayout .ant-layout-header {
  background: #f8f8f8;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.bgGray {
  background: #fafafa;
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: #fca311;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header .logo {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
}

.header .logo .fas {
  color: #fca311;
  margin: 0 5px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #333;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: "";
  background: #fca311;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #333;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: "";
  background: #fca311;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #fca311;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
hero
---- 
*/
.heroBlock {
  background: url("assets/images/bg2-compressed.jpg") no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #fca311 !important;
}

.heroBlock .content {
  max-width: 640px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
  color: white;
}

.heroBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heroHeader {
  position: relative;
  top: 5vh;
  text-align: center;
  color: #ffffff;
}

.heroHeader h1,
.heroHeader h3 {
  color: #ffffff;
}

.heroHeader h1 {
  font-size: 35px;
  font-weight: 200;
}
.heroHeader h3 {
  color: #fca311;
  font-size: 15px !important;
}

.heroCarousel {
  text-align: center;
  position: relative;
  top: 15vh;
  padding: 20px;
}

.heroCarousel .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.heroCarousel .content {
  position: relative;
  top: 20px;
}

.content h3,
.content p {
  color: white;
}

.content #specialise {
  color: #fca311;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
}

.heroBlock p {
  margin: 0 0 30px;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}

.aboutBlock .icon {
  font-size: 40px;
  color: #fca311;
  margin: 0 0 10px;
}

.aboutBlock .content {
  text-align: center;
}

/* 
how it works
------------
*/
.worksBlock {
  background: url("assets/images/bg-works-compressed.jpg") no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.worksBlock:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock h2 {
  color: #fff;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder button {
  font-size: 40px;
  line-height: 1;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 30px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}

/* 
faqs
----
*/
.faqBlock .ant-collapse {
  margin: 0 0 40px;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
.contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #111 !important;
  color: #fff !important;
  padding: 60px 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .fas {
  color: #fca311;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #fca311;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
}

.btnChange {
  background: #fca311 !important;
  border-color: #fca311 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fca311 !important;
  border-color: #fca311 !important;
}

.fa-envelope:before,
.fa-phone:before,
.fa-map:before {
  color: #fca311;
  position: relative;
  top: 12px;
}

.ant-anchor-link-active > .ant-anchor-link-title {
  color: #fca311 !important;
}

.ant-anchor-link-title:hover {
  color: #fca311 !important;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fca311 !important;
  color: white !important;
  content: "";
}

.ant-btn-primary {
  color: #fff !important;
  border-color: #fca311 !important;
  background: #fca311 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  z-index: 1000000000000;
}

.ant-btn-primary a {
  color: #fff !important;
}

/* .myForm input {
  border: none;
  border-bottom: solid 1px rgb(124, 124, 124);
  width: 90%;
  color: #fca311;
}

.myForm textarea {
  border: solid 1px rgb(124, 124, 124);
  width: 90%;
  resize: none;
  color: #fca311;
}

.myForm input:focus,
textarea:focus,
label:focus {
  outline: none;
  border-bottom: solid 1px #fca311;
}

.myForm label {
  color: rgb(124, 124, 124);
}

.myForm button {
  border: none;
  padding: 10px;
  background-color: #fca311;
  color: #ffffff;
  border-radius: 5px;
}

.myForm button:hover {
  background-color: #f1b048;
  color: #ffffff;
} */

p,
input,
textarea,
label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 5%;
}

/* FORM DESIGN */
form {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  padding: 20px 0;
}

.myForm input,
.myForm textarea,
.myForm label {
  display: block;
  margin: 0 auto;
  color: #828282;
  width: 100%;
}

.myForm input,
.myForm textarea {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #828282;
}

.myForm input[type="submit"] {
  background-color: #fca311;
  padding: 15px 0;
  color: #ffffff;

  font-size: 18px;
  border-bottom: none;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.myForm input[type="submit"]:hover {
  color: #828282;
}

.myForm input,
.myForm textarea {
  color: #fca311;
  font-size: 18px;
  padding: 10px;
  outline: none;
  resize: none;
}

.myForm input:focus,
.myForm textarea:focus {
  border: 1px solid #828282;
}

.centerCard {
  display: flex;
  justify-content: center;
}

.contactInfoContainer {
  margin-bottom: 45px;
}

.contactInfo {
  display: flex;
}

.contactInfo h3 {
  margin: 0 10px;
}

.missionBlock {
  background-color: #111111;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.missionBlock .titleHolder {
  position: relative;
}

.missionBlock .container-fluid .content {
  background-color: red !important;
  z-index: 100;
}

.missionCarousel {
  z-index: 100;
}

.fa-arrow-down:before {
  color: #fca311;
}
